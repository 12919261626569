import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { AuthService } from "../service/auth.service";
import { JwtService } from "src/app/dashboard/services/jwt.service";
import { Router } from "@angular/router";
import { ResponseData } from "src/app/dashboard/model/interface.model";

@Component({
  selector: "app-two-factor-auth",
  templateUrl: "./two-factor-auth.component.html",
  styleUrls: ["./two-factor-auth.component.css"],
})
export class TwoFactorAuthComponent implements OnInit {
  _2faAuthForm: FormGroup;
  isLoading: boolean = false;
  error_message: string = null;

  logo: string = environment.logo;
  logo_width: number = environment.logo_width;

  constructor(
    private auth: AuthService,
    private jwt: JwtService,
    private router: Router
  ) {}

  ngOnInit() {
    this._2faAuthForm = new FormGroup({
      token: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit() {
    this.isLoading = true;

    let formData: { code: string } = {
      code: this._2faAuthForm.value.token,
    };

    this.auth.login_2fa(formData).subscribe(
      (response: ResponseData) => {
        this.isLoading = false;
        if (response.request_status) {
          this.isLoading = true;

          if (response.data.token) {
            this.error_message = null;
            this.jwt.setToken(response.data.token);
            setTimeout(() => {
              this.router.navigate(["/dashboard"]);
            }, 1000);
          }
        } else {
          this.error_message = response.error_message;
        }
      },
      (error) => {
        this.error_message =
          "Network Error: " + error.error.error_message ||
          "something went wrong, try again later";
        this.isLoading = false;
      }
    );
  }
}
