import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import {
  NbThemeModule,
  NbLayoutModule,
  NbInputModule,
  NbCardModule,
  NbButtonModule,
  NbSpinnerModule,
  NbAlertModule,
  NbIconModule,
  NbMenuModule,
} from "@nebular/theme";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { ErrorComponent } from "./pages/error/error.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TwoFactorAuthComponent } from "./pages/two-factor-auth/two-factor-auth.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthInterceptorService } from './dashboard/services/auth.interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    ErrorComponent,
    TwoFactorAuthComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    ReactiveFormsModule,
    NbSpinnerModule,
    HttpClientModule,
    NbAlertModule,
    NbEvaIconsModule,
    NbIconModule,
    NbMenuModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
