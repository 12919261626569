const production: boolean = true;

export const environment = {
  production: production,
  logo: "/assets/images/logo.png",
  endpoint: "https://dcfx-api.tech4broker.in/backend",
  crm_title: "BackOffice Administrator",
  logo_width: 200,
  inner_logo_width: 150,
  ib_levels: ["ib1","ib2","ib3","ib5","ib5"],
};