import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "../service/auth.service";
import { ResponseData } from "../models/interface.model";
import { environment } from "../../../environments/environment";
import { LoginRequestData } from "../models/interface.model";
import { JwtService } from "src/app/dashboard/services/jwt.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private jwt: JwtService,
    private router: Router
  ) {}

  loginForm: FormGroup;
  isLoading: boolean = false;
  logo: string = environment.logo;
  crm_title: string = environment.crm_title;
  logo_width: number = environment.logo_width;

  error = null;
  loginResponse = null;

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit() {
    this.isLoading = true;

    let formData: LoginRequestData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };

    this.auth.login(formData).subscribe(
      (response: ResponseData) => {
        this.loginResponse = response;
        this.error = null;
        this.isLoading = false;

        if (response.request_status) {
          this.isLoading = true;

          if (response.data.token) {
            this.jwt.setToken(response.data.token);
          }

          if (response.data.two_factor_auth_enabled) {
            this.loginResponse = null;
            setTimeout(() => {
              this.router.navigate(["/two-fact-auth"]);
            }, 500);
          }

          if (!response.data.two_factor_auth_enabled && response.data.token) {
            setTimeout(() => {
              this.router.navigate(["/dashboard"]);
            }, 1000);
          }
        }
      },
      (error) => {
        this.error = error;
        this.loginResponse = null;
        this.isLoading = false;
      }
    );
  }
}
