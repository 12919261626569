import { Component, OnInit } from "@angular/core";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"],
})
export class NotFoundComponent implements OnInit {
  logo: string = environment.logo;
  logo_width: number = environment.logo_width;
    
  constructor() {}
  ngOnInit() {}
}
