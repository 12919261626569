import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Country, LoginRequestData } from "../models/interface.model";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  endpoint: string = environment.endpoint;
  constructor(private http: HttpClient) {}

  getCountryList() {
    return this.http.get<Country[]>("/assets/country.json");
  }

  login(request: LoginRequestData) {
    return this.http.post(this.endpoint + "/login", request);
  }

  login_2fa(request: { code: string }) {
    return this.http.post(this.endpoint + "/verify_2fa_auth", request);
  }
}
