import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { TwoFactorAuthComponent } from "./pages/two-factor-auth/two-factor-auth.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "two-fact-auth", component: TwoFactorAuthComponent },
  { path: "404", component: NotFoundComponent },
  {
    path: "dashboard",
    loadChildren: () =>
      import("src/app/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
